import * as React from "react";
import "../../styles/component/layout/AppHeader.scss";
import { AppContext } from "../../setup/context";
import { AdminPreferences, APIOutput, Instance } from "@amzn/ask-legal-domain";
import { SearchInputBar } from "../search/SearchInputBar";
import { PreferenceSelectionModal } from "../instance/PreferenceSelectionModal";
import { AppNavigationHeader } from "../navigation/AppNavigationHeader";
const APP_LOGO = require("../../assets/pathfinder-logo-bw-new.png").default;
const AWS_LEGAL_LOGO = require("../../assets/aws-legal-logo-wb.png").default;
const AMAZON_LEGAL_LOGO = require("../../assets/amazon-legal-logo-wb.png").default;

export interface AppHeaderProps {
    instanceId: string;
}

/**
 * Responsive Application Header
 */
export const AppHeader = (props: AppHeaderProps) => {
    const context = React.useContext(AppContext);

    const [loadingProps, setLoadingProps] = React.useState<{}>();
    const [instanceName, setInstanceName] = React.useState<string>();
    const [orgLogo, setOrgLogo] = React.useState<any>();
    const [preferenceSelectionModalProps, setPreferenceSelectionModalProps] =
        React.useState<React.ComponentProps<typeof PreferenceSelectionModal>>();

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const init = async () => {
        setLoadingProps({});
        try {
            const loadInstanceOutput = await context.getInstanceAPI().load(props.instanceId);
            const output = APIOutput.fromRaw<Instance>(loadInstanceOutput.data);
            if (output.isOk()) {
                setInstanceName(output.data.name);
                setOrgLogo(
                    getOrganizationLogo(output.data.preferences.organization)
                );
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingProps(undefined);
        }
    };

    const getOrganizationLogo = (org: AdminPreferences.Organization) => {
        switch (org) {
            case AdminPreferences.Organization.AMAZON_LEGAL:
                return AMAZON_LEGAL_LOGO;
            case AdminPreferences.Organization.AWS_LEGAL:
            default:
                return AWS_LEGAL_LOGO;
        }
    };

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const handlePreferenceSelection = () => {
        setPreferenceSelectionModalProps({
            onDismiss: () => setPreferenceSelectionModalProps(undefined)
        });
    };

    React.useEffect(() => {
        init();
    }, [props.instanceId]);

    return <nav className="app-navigation">
        <div className={`nav-container${isMenuOpen ? " active" : ""}`}>
            <div className="nav-left">
                <a href={`/#/instance/${props.instanceId}/home`}>
                    <img src={APP_LOGO} alt="Pathfinder" className="app-logo" draggable={false} />
                </a>
                <span className="nav-search-bar"><SearchInputBar instanceId={props.instanceId} disabled={!!loadingProps} /></span>
            </div>

            {instanceName && <div className={`nav-center${isMenuOpen ? " active" : ""}`}>
                <span className="nav-instance-name">{instanceName}</span>
                <hr className="solid-orange"/>
                <AppNavigationHeader instanceId={props.instanceId} isMenuOpen={isMenuOpen} />
            </div>}

            {orgLogo && <div className="nav-right">
                <img src={orgLogo} alt="Org Logo" className="org-logo" draggable={false} />
                <span className="nav-badge" tabIndex={0} onClick={handlePreferenceSelection}>{instanceName}</span>
                <span className="nav-badge-info">Click the badge for other legal orgs</span>
                {preferenceSelectionModalProps && <PreferenceSelectionModal {...preferenceSelectionModalProps} />}
            </div>}

            <div className="nav-hamburger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </nav>;
};