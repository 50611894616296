import {
    AdvancedList,
    AdvancedListSearchItem,
    ChoiceOption,
    IdentityRef,
    LoadAdvancedListCommandOutput,
    Pagination as SDS_Pagination,
    SearchAdvancedListItemsCommandOutput,
    Query,
    JoinOperation,
    FieldDefinitionDataType,
    EntityExtraVersionRef
} from "@amzn/altar-sds-client";
import {
    APIOutput,
    AdvancedListContent,
    ContainerRef,
    EmployeeIdentity,
    Identity
} from "@amzn/ask-legal-domain";
import {
    Button,
    CollectionPreferences,
    CollectionPreferencesProps,
    Flashbar,
    FlashbarProps,
    Header,
    Pagination,
    PaginationProps,
    SpaceBetween,
    Spinner,
    SpinnerProps,
    Table,
    TableProps,
    PropertyFilter,
    PropertyFilterProps,
    ButtonDropdown,
    Modal,
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AdvancedListPolarisFactory } from "../../../factory/polaris/advanced-list-polaris-factory";
import { AppContext } from "../../../setup/context";
import { CreateAdvancedListItemModal } from "../../advanced-list/CreateAdvancedListItemModal";
import { UpdateAdvancedListItemModal } from "../../advanced-list/UpdateAdvancedListItemModal";
import { Builder } from "builder-pattern";
import { AdvancedListConstants } from "../../../utils/advanced-list.constant";
import { ViewAdvancedListItemModal } from "../../advanced-list/ViewAdvancedListItemModal";
import { DeleteAdvancedListItemModal } from "../../advanced-list/DeleteAdvancedListItemModal";
import { BatchDeleteAdvancedListItemModal } from "../../advanced-list/BatchDeleteAdvancedListItemModal";
import { ExportAdvancedListItemsModal } from "../../advanced-list/modal-components/ExportAdvancedListItemsModal";
import { AdvancedListModel } from "../../../model/advanced-list-model";
import { ImportAdvancedListItemsModal } from "../../advanced-list/modal-components/ImportAdvancedListItemsModal";
import { ChangeSummary } from "../edit-content/advanced-list/ChangeSummary";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_INDEX = 1;

export const AdvancedListContentView = (props: {
    content: AdvancedListContent;
    containerRef: ContainerRef;
    showEditControls?: boolean;
}) => {
    const context = React.useContext(AppContext);
    const [userIdentity, setUserIdentity] = React.useState<Identity>(null);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [tableProps, setTableProps] = React.useState<
        Pick<
            TableProps<AdvancedListSearchItem>,
            "items" | "loading" | "loadingText" | "empty" |
            "columnDefinitions" | "sortingColumn" | "sortingDescending"
        > & {
            advancedList?: AdvancedList;
        }
    >();
    const [createItemModalProps, setCreateItemModalProps] =
        React.useState<Parameters<typeof CreateAdvancedListItemModal>[0]>();
    const [updateItemModalProps, setUpdateItemModalProps] =
        React.useState<Parameters<typeof UpdateAdvancedListItemModal>[0]>();
    const [viewItemModalProps, setViewItemModalProps] =
        React.useState<Parameters<typeof ViewAdvancedListItemModal>[0]>();
    const [deleteItemModalProps, setDeleteItemModalProps] =
        React.useState<Parameters<typeof DeleteAdvancedListItemModal>[0]>();
    const [batchDeleteItemModalProps, setBatchDeleteItemModalProps] =
        React.useState<Parameters<typeof BatchDeleteAdvancedListItemModal>[0]>();
    const [exportAdvancedListItemsModalProps, setExportAdvancedListItemsModalProps] =
        React.useState<Parameters<typeof ExportAdvancedListItemsModal>[0]>();
    const [importAdvancedListItemsModalProps, setImportAdvancedListItemsModalProps] =
        React.useState<Parameters<typeof ImportAdvancedListItemsModal>[0]>();
    const [paginationProps, setPaginationProps] = React.useState<
        Pick<PaginationProps, "currentPageIndex" | "pagesCount">
    >({
        currentPageIndex: DEFAULT_PAGE_INDEX,
        pagesCount: 0
    });
    const [collectionPreferences, setCollectionPreferences] = React.useState<Partial<CollectionPreferencesProps>>({
        pageSizePreference: {
            title: "Select page size",
            options: [
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "25", value: 25 }
            ]
        },
        cancelLabel: "Cancel",
        confirmLabel: "Confirm",
        title: "Table Preferences",
        preferences: {
            pageSize: DEFAULT_PAGE_SIZE
        }
    });
    const [itemCount, setItemCount] = React.useState(0);
    const [propertyFilterProps, setPropertyFilterProps] = React.useState<
        Pick<PropertyFilterProps, "filteringProperties" | "hideOperations" | "filteringOptions"> & {
        query?: Pick<PropertyFilterProps.Query, "operation"> & {
            tokens: AdvancedListPolarisFactory.PropertyFilter.EnrichedFilterToken<any>[];
        };
    }
    >({
        query: { tokens: [], operation: "and" },
        filteringProperties: [],
        filteringOptions: [],
        hideOperations: true
    });
    const [selectedSearchItems, setSelectedSearchItems] = React.useState<AdvancedListSearchItem[]>([]);
    const [exportSpinnerProps, setExportSpinnerProps] = React.useState<SpinnerProps>();

    const isLive = window.location.href.includes("/live");

    async function init() {
        const userIdentity = await context.getIdentity();
        if (!userIdentity) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: "No current user information. Try to refresh the page again."
                    }
                ]
            });
            return;
        }
        setUserIdentity(userIdentity);

        if (!props.content?.entityRef) {
            // New blank container draft
            setFlashbarProps(undefined);
            return;
        }

        try {
            setSpinnerProps({});
            const by: IdentityRef = {
                id: userIdentity.id,
                realm: "Amazon",
                type: "Person"
            };
            const loadOutput = await context.getAdvancedListAPI().load({
                entityId: props.content?.entityRef.entityId,
                repositoryId: props.content?.entityRef.repositoryRef.repositoryId,
                by: by
            });

            let columnDefinitions: TableProps.ColumnDefinition<AdvancedListSearchItem>[] = [];
            let advancedList: AdvancedList;
            let emptyText: string;

            const output = APIOutput.fromRaw<LoadAdvancedListCommandOutput>(loadOutput.data);
            if (output.isOk()) {
                // Have existing AL
                advancedList = Builder(output.data.body)
                    .fieldDefinitions(output.data.body.fieldDefinitions.filter((def) => !def.deprecated))
                    .build();
                if (isLive) {
                    const visibleFieldDefinitions = AdvancedListPolarisFactory.Table.filterFieldDefinitionsByVisibility(
                        advancedList.fieldDefinitions ?? [],
                        props.content.visibleFields ?? []
                    );

                    columnDefinitions = [
                        ...AdvancedListPolarisFactory.Table.buildColumnDefinitions(
                            visibleFieldDefinitions,
                            props.content.fieldConfigurations,
                            props.content.visibleFields ?? []
                        )
                    ];
                } else {
                    columnDefinitions = [
                      ...AdvancedListPolarisFactory.Table.buildColumnDefinitions(
                        AdvancedListPolarisFactory.Table.updateFieldDefinitions(
                            props.content.updateAdvancedListPayload.reorderFieldDefinitionSequence?.length ?
                                AdvancedListModel.sortAdvancedListFieldDefinitions(
                                    advancedList.fieldDefinitions, props.content.updateAdvancedListPayload.reorderFieldDefinitionSequence
                                ) : advancedList?.fieldDefinitions || [],
                            props.content.updateAdvancedListPayload
                                .addFieldDefinitionsList ?? []
                        ).filter((def) => !def.deprecated),
                        props.content.fieldConfigurations,
                        props.content.visibleFields ?? []
                      ),
                    ];
                }
            } else if (output.err.code === 400 && isLive) {
                // No existing AL and is on live page
                emptyText = "List is under creation. Try again after a few seconds.";
            }

            if (!isLive) {
                // Draft mode. Add all pending columns
                columnDefinitions.push(
                  ...AdvancedListPolarisFactory.Table.addFieldDefinitions(
                    advancedList?.fieldDefinitions ?? [],
                    props.content?.updateAdvancedListPayload
                      ?.addFieldDefinitionsList || []
                  ).map<TableProps.ColumnDefinition<AdvancedListSearchItem>>(
                    (f) => ({
                      header: `${f.displayName} (pending)`,
                      id: f.displayName,
                      cell: (item) => <></>,
                    })
                  )
                );
            }

            setTableProps((prev) => ({
                ...prev,
                advancedList: advancedList,
                columnDefinitions: [...columnDefinitions],
                loading: false,
                empty: emptyText
            }));

            if (advancedList) {
                setPropertyFilterProps((prev) => ({
                    ...prev,
                    filteringProperties: AdvancedListPolarisFactory.PropertyFilter.getFilterProperties(
                        advancedList.fieldDefinitions
                    )
                }));
            }
            setFlashbarProps(undefined);
        } catch (err) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: (err as Error).message
                    }
                ]
            });
        } finally {
            setSpinnerProps(undefined);
        }
    }

    async function searchItems() {
        try {
            setTableProps((prev) => ({
                ...prev,
                loading: true,
                loadingText: "loading items"
            }));
            const paginationEffective = {
                pageIndex: paginationProps.currentPageIndex || DEFAULT_PAGE_INDEX,
                pageSize: collectionPreferences.preferences?.pageSize || DEFAULT_PAGE_SIZE
            };

            const searchItemsOutput = await context.getAdvancedListAPI().searchItems({
                listEntityId: props.content.entityRef.entityId,
                listRepositoryId: props.content.entityRef.repositoryRef.repositoryId,
                pagination: Builder<SDS_Pagination>()
                    .pageSize(paginationEffective.pageSize)
                    .pageIndex(paginationEffective.pageIndex)
                    .build(),
                query: Builder<Query>()
                    .operation(JoinOperation.AND)
                    .propertyTokens(
                        propertyFilterProps.query.tokens.map((t) =>
                            AdvancedListPolarisFactory.PropertyFilter.convertEnhancedPropertyFilterTokenToFilterQueryValue(
                                t,
                                tableProps.advancedList.fieldDefinitions
                            )
                        )
                    )
                    .build(),
                sort: tableProps.sortingColumn?.sortingField && {
                    propertyName: tableProps.sortingColumn.sortingField,
                    descending: tableProps.sortingDescending || false
                },
                by: {
                    id: userIdentity.id,
                    realm: "Amazon",
                    type: "Person"
                }
            });

            const output = APIOutput.fromRaw<SearchAdvancedListItemsCommandOutput>(searchItemsOutput.data);
            if (output.isErr()) {
                setTableProps((prev) => ({
                    ...prev,
                    loading: false,
                    loadingText: output.err.message
                }));
            } else {
                setTableProps((prev) => ({
                    ...prev,
                    loading: false,
                    loadingText: undefined,
                    items: output.data.items
                }));
                setItemCount(output.data.total);

                setPaginationProps((prev) => ({
                    ...prev,
                    pagesCount: Math.ceil(output.data.total! / paginationEffective.pageSize)
                }));
            }
        } catch (err) {
            setTableProps((prev) => ({
                ...prev,
                loading: false,
                loadingText: (err as Error).message
            }));
        } finally {
            setTableProps((prev) => ({
                ...prev,
                loading: false
            }));
        }
    }

    async function loadFilterOptions(detail: PropertyFilterProps.LoadItemsDetail) {
        if (!detail.filteringProperty) return;
        if (!detail.filteringOperator) return;

        const fieldDef = tableProps.advancedList.fieldDefinitions.find(
            (e) => e.fieldKey === detail.filteringProperty.key
        );

        if (!fieldDef) return;
        if (fieldDef.dataType === FieldDefinitionDataType.choice) {
            setPropertyFilterProps((prev) => ({
                ...prev,
                filteringOptions:
                    AdvancedListPolarisFactory.PropertyFilter.getFilterOptions<ChoiceOption>(
                        fieldDef,
                        fieldDef.choiceOptions,
                        (t) => t.displayValue
                    ),
                filteringStatusType: "finished"
            }));
            return;
        }
        if (fieldDef.dataType === FieldDefinitionDataType.boolean) {
            setPropertyFilterProps((prev) => ({
                ...prev,
                filteringOptions:
                    AdvancedListPolarisFactory.PropertyFilter.getFilterOptions<AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES>(
                        fieldDef,
                        [
                            AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes,
                            AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No
                        ]
                    ),
                filteringStatusType: "finished"
            }));
            return;
        }
        if (
            fieldDef.dataType === FieldDefinitionDataType.IdentityRef ||
            fieldDef.dataType === FieldDefinitionDataType.multiIdentityRef
        ) {
            if (!detail.filteringText || detail.filteringText.trim().length < 3) {
                return;
            }
            setPropertyFilterProps((prev) => ({
                ...prev,
                filteringStatusType: "loading"
            }));
            const userSearchOutput = await context.getUserSearchAPI().searchUserByLogin({
                limit: 20,
                userName: detail.filteringText.trim()
            });
            const output = APIOutput.fromRaw<EmployeeIdentity[]>(userSearchOutput.data);
            if (output.isOk()) {
                setPropertyFilterProps((prev) => ({
                    ...prev,
                    filteringOptions: AdvancedListPolarisFactory.PropertyFilter.getFilterOptions<EmployeeIdentity>(
                        fieldDef,
                        output.data,
                        (t) => `${t.name} (${t.id})`
                    ),
                    filteringStatusType: "finished"
                }));
            } else {
                setPropertyFilterProps((prev) => ({
                    ...prev,
                    filteringOptions: [],
                    filteringStatusType: "error",
                    filteringErrorText: output.err.message
                }));
            }
            return;
        }
    }

    async function handleQueryChange(detail: PropertyFilterProps.Query) {
        const tokens = AdvancedListPolarisFactory.PropertyFilter.toTokens(
            detail.tokens,
            tableProps.advancedList.fieldDefinitions
        );
        setPropertyFilterProps((prev) => ({
            ...prev,
            query: {
                ...prev.query,
                operation: detail.operation,
                tokens: tokens
            }
        }));
    }

    function onItemAction(item: AdvancedListSearchItem, action: AdvancedListPolarisFactory.Table.ItemActionType) {
        switch (action) {
            case "edit":
                setUpdateItemModalProps({
                    itemRef: item.entityExtraVersionRef,
                    advancedList: tableProps.advancedList,
                    containerRef: props.containerRef,
                    by: {
                        id: userIdentity.id,
                        realm: "Amazon",
                        type: "Person"
                    }
                });
                break;
            case "view":
                setViewItemModalProps({
                    itemRef: item.entityExtraVersionRef,
                    advancedList: tableProps.advancedList,
                    fieldConfigurations: props.content.fieldConfigurations,
                    by: {
                        id: userIdentity.id,
                        realm: "Amazon",
                        type: "Person"
                    }
                });
                break;
            case "delete":
                setDeleteItemModalProps({
                    itemRef: item.entityExtraVersionRef,
                    containerRef: props.containerRef,
                    by: {
                        id: userIdentity.id,
                        realm: "Amazon",
                        type: "Person"
                    }
                });
                break;
            default:
                break;
        }
    }

    React.useEffect(() => {
        setPaginationProps((prev) => ({
            ...prev,
            currentPageIndex: DEFAULT_PAGE_INDEX,
            pagesCount: Math.ceil(itemCount / collectionPreferences?.preferences?.pageSize)
        }));
    }, [collectionPreferences.preferences?.pageSize]);

    React.useEffect(() => {
        init();
    }, []);

    React.useEffect(() => {
        if (tableProps?.advancedList) {
            searchItems();
        }
    }, [tableProps?.advancedList,
        tableProps?.sortingColumn,
        tableProps?.sortingDescending,
        paginationProps.currentPageIndex,
        collectionPreferences?.preferences?.pageSize,
        propertyFilterProps?.query
    ]);

    return (
        <>
            {spinnerProps && <Spinner />}
            {flashbarProps && <Flashbar {...flashbarProps} />}
            {exportSpinnerProps && <Modal visible={true}><Spinner /></Modal>}
            {exportAdvancedListItemsModalProps && (
                <ExportAdvancedListItemsModal
                    {...exportAdvancedListItemsModalProps}
                    onCanceled={() => {
                        setExportAdvancedListItemsModalProps(undefined);
                    }}
                    onConfirm={() => {
                        setExportAdvancedListItemsModalProps(undefined);
                        setSelectedSearchItems([]);
                    }}
                />
            )}
            {createItemModalProps && (
                <CreateAdvancedListItemModal
                    {...createItemModalProps}
                    fieldConfigurations={props.content.fieldConfigurations}
                    onCreated={(item) => {
                        setTableProps((prev) => ({
                            ...prev,
                            items: [item, ...prev?.items]
                        }));
                        setCreateItemModalProps(undefined);
                    }}
                    onCanceled={() => {
                        setCreateItemModalProps(undefined);
                    }}
                    containerRef={props.containerRef}
                />
            )}
            {updateItemModalProps && (
                <UpdateAdvancedListItemModal
                    {...updateItemModalProps}
                    fieldConfigurations={props.content.fieldConfigurations}
                    onUpdated={(item) => {
                        setTableProps((prev) => ({
                            ...prev,
                            items: [
                                item,
                                ...prev.items.filter(
                                    (e) =>
                                        e.entityExtraVersionRef.entityExtraRef.extraId !==
                                        item.entityExtraVersionRef.entityExtraRef.extraId
                                )
                            ]
                        }));
                        setUpdateItemModalProps(undefined);
                    }}
                    onCanceled={() => {
                        setUpdateItemModalProps(undefined);
                    }}
                />
            )}
            {viewItemModalProps && (
                <ViewAdvancedListItemModal
                    {...viewItemModalProps}
                    onCanceled={() => {
                        setViewItemModalProps(undefined);
                    }}
                />
            )}
            {deleteItemModalProps && (
                <DeleteAdvancedListItemModal
                    {...deleteItemModalProps}
                    onDeleted={(itemRef: EntityExtraVersionRef) => {
                        setTableProps((prev) => ({
                            ...prev,
                            items: [
                                ...prev.items.filter(
                                    (e) =>
                                        e.entityExtraVersionRef.entityExtraRef.extraId !==
                                        itemRef.entityExtraRef.extraId
                                )
                            ]
                        }));
                        setDeleteItemModalProps(undefined);
                    }}
                    onCanceled={() => {
                        setDeleteItemModalProps(undefined);
                    }}
                />
            )}
            {batchDeleteItemModalProps && (
                <BatchDeleteAdvancedListItemModal
                    {...batchDeleteItemModalProps}
                    onDeleted={(itemRefList: EntityExtraVersionRef[]) => {
                        setTableProps((prev) => ({
                            ...prev,
                            selectedItems: [],
                            items: [
                                ...prev.items.filter(
                                    (e) =>
                                    !itemRefList.some(
                                        (deletedRef) =>
                                            deletedRef.entityExtraRef.extraId ===
                                            e.entityExtraVersionRef.entityExtraRef.extraId
                                    )
                                )
                            ]
                        }));
                        setBatchDeleteItemModalProps(undefined);
                        setSelectedSearchItems([]);
                    }}
                    onCanceled={() => {
                        setBatchDeleteItemModalProps(undefined);
                    }}
                />
            )}
            {importAdvancedListItemsModalProps && (
                <ImportAdvancedListItemsModal
                    {...importAdvancedListItemsModalProps}
                    onCreated={(items) => {
                        setTableProps((prev) => ({
                            ...prev,
                            items: [...items, ...prev.items]
                        }));
                        setImportAdvancedListItemsModalProps(undefined);
                    }}
                    onCanceled={() => setImportAdvancedListItemsModalProps(undefined)}
                />
            )}
            {!isLive &&
                <ChangeSummary advancedList={tableProps?.advancedList} content={props.content} />
            }
            {tableProps && (
                <Table
                    {...tableProps}
                    selectionType="multi"
                    columnDefinitions={[
                        ...tableProps.columnDefinitions,
                        AdvancedListPolarisFactory.Table.buildActionColumnDefinition(onItemAction)
                    ]}
                    header={
                        <Header
                            actions={
                                <SpaceBetween size="s" direction="horizontal">
                                    <>
                                    {
                                        <Button
                                            disabled={selectedSearchItems?.length < 1}
                                            iconName="remove"
                                            onClick={(e) => {
                                                setBatchDeleteItemModalProps({
                                                    itemRefList: selectedSearchItems.map((item) => item.entityExtraVersionRef!),
                                                    containerRef: props.containerRef
                                                });
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    }
                                    </>
                                    <Button
                                        variant="primary"
                                        disabled={!tableProps.advancedList}
                                        iconName="add-plus"
                                        onClick={(e) => {
                                            setCreateItemModalProps({
                                                listRef: props.content?.entityRef,
                                                advancedList: tableProps.advancedList,
                                                by: {
                                                    id: userIdentity.id,
                                                    realm: "Amazon",
                                                    type: "Person"
                                                },
                                                containerRef: props.containerRef
                                            });
                                        }}
                                    >
                                        New Item
                                    </Button>
                                    <ButtonDropdown
                                        items={[
                                            { text: "Export to Excel", id: "export_items", disabled: false },
                                            { text: "Import from Excel", id: "import_items", disabled: false }
                                        ]}
                                        onItemClick={(e) => {
                                            if (e.detail.id === "export_items") {
                                                setExportAdvancedListItemsModalProps({
                                                    advancedListEntityRef: props.content.entityRef,
                                                    fieldConfigurations: props.content.fieldConfigurations,
                                                    selectedItems: selectedSearchItems.map((item) => item.entityExtraVersionRef.entityExtraRef!)
                                                });
                                            } else if (e.detail.id === "import_items") {
                                                setImportAdvancedListItemsModalProps((prev) => ({
                                                    ...prev,
                                                    containerRef: props.containerRef,
                                                    advancedListRef: props.content.entityRef,
                                                    fieldConfigurations: props.content.fieldConfigurations
                                                }));
                                            }
                                        }}
                                    >
                                        Actions
                                    </ButtonDropdown>
                                </SpaceBetween>
                            }
                        />
                    }
                    pagination={
                        <Pagination
                            {...paginationProps}
                            ariaLabels={{
                                nextPageLabel: "Next page",
                                previousPageLabel: "Previous page",
                                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
                            }}
                            onChange={(e) => {
                                setPaginationProps((prev) => ({
                                    ...prev,
                                    pagesCount: Math.ceil(itemCount / collectionPreferences.preferences.pageSize),
                                    currentPageIndex: e.detail.currentPageIndex
                                }));
                            }}
                        />
                    }
                    preferences={
                        <CollectionPreferences
                            {...collectionPreferences}
                            onConfirm={(e) => {
                                setCollectionPreferences((prev) => ({ ...prev, preferences: e.detail }));
                            }}
                        />
                    }
                    filter={
                        <PropertyFilter
                            {...propertyFilterProps}
                            disableFreeTextFiltering={false}
                            onChange={(e) => {
                                handleQueryChange(e.detail);
                            }}
                            onLoadItems={(e) => {
                                loadFilterOptions(e.detail);
                            }}
                            i18nStrings={{clearFiltersText: "Clear filters"}}
                        />
                    }
                    onSortingChange={(e) => {
                        setTableProps((prev) => ({
                            ...prev,
                            sortingColumn: e.detail.sortingColumn,
                            sortingDescending: e.detail.isDescending
                        }));
                    }}
                    onSelectionChange={({detail}) => {
                        setSelectedSearchItems(detail.selectedItems);
                    }}
                    selectedItems={selectedSearchItems}
                    stickyColumns={{ first: 0, last: 1 }}
                />
            )}
        </>
    );
};
