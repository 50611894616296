import * as React from "react";
import "../../styles/component/layout/Header.scss";
import "../../styles/component/layout/Navigation.scss";
import "../../styles/util-styles.scss";
const PATHFINDER_LOGO = require("../../assets/pathfinder-logo-bw-new.png").default;
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { INavigation } from "@amzn/ask-legal-domain";
import { InstanceBadge } from "./InstanceBadge";
import { getNavigationTabContent } from "../home-page-widgets/navigation-widget";
import { SearchInputBar } from "../search/SearchInputBar";
import { Preference } from "../../setup/preference";
import { AppHeader } from "./AppHeader";

export type HeaderType = "Home" | "InstanceHome" | "Other" | "LivePage";

export const SherpaHeader = (props: { headerType: HeaderType, instanceId?: string }) => {
    const context = React.useContext(AppContext);

    if (context.hasLab(Preference.Lab.UIRefresh) && props.headerType === "LivePage") {
        return <AppHeader
            instanceId={props.instanceId}
        />;
    }

    const loadNavigationRunner = useAPI(
        context.getNavigationAPI().load
    );
    const [toggleExpanded, setToggleExpanded] = React.useState<boolean>(false);
    const [activeNavTab, setActiveNavTab] = React.useState<string>(null);
    const [activeNavGroup, setActiveNavGroup] = React.useState<string>(null);
    const [activeNavLink, setActiveNavLink] = React.useState<string>(null);

    const handleActiveNavTab = (id: string) => {
        if (activeNavTab === id) {
            setActiveNavTab(null);
        } else {
            setActiveNavTab(id);
        }
    };

    const handleActiveNavGroup = (id: string) => {
        if (activeNavGroup === id) {
            setActiveNavGroup(null);
        } else {
            setActiveNavGroup(id);
        }
    };

    const handleActiveNavLink = (id: string) => {
        if (activeNavLink === id) {
            setActiveNavLink(null);
        } else {
            setActiveNavLink(id);
        }
    };

    React.useEffect(() => {
        if (!props.instanceId) return;
        loadNavigationRunner.submitRun(
            INavigation.LoadNavigationInput.create({
                navigationId: props.instanceId
            })
        );
    }, [props.instanceId]);

    return (
        <div className={`header ${props.headerType === "LivePage" ? "livepage" : ""}`}>
            <a className="header-brand" href={!props.instanceId ? `#` : `#/instance/${props.instanceId}/home`}>
                <img src={String(PATHFINDER_LOGO)} height="50px" draggable={false} alt="awslegal" />
            </a>
            <nav
                className={`awsui-polaris-dark-mode navigation ${toggleExpanded ? "responsive" : ""
                    } ${props.headerType === "Home" ? "transparent-background" : ""
                    }`}>
                {loadNavigationRunner.status === "Succeeded" && (
                    loadNavigationRunner.data.output.tabs.map(tab =>
                        <div className={`navtab ${props.headerType === "LivePage" ? "vertical-bottom" : "vertical-center"}`}>
                            <span className={`${props.headerType === "LivePage" ? "livepage-nav" : ""}`}>
                                <button
                                    id={tab.id}
                                    className={`tabname ${props.headerType === "LivePage" ? "livepage" : ""}`}
                                    aria-expanded={activeNavTab === tab.id}
                                    aria-controls={tab.name}
                                    onClick={() => handleActiveNavTab(tab.id)}>{tab.name}</button>
                            </span>
                            <div onMouseLeave={(event) => {
                                setActiveNavTab(null);
                                setActiveNavGroup(null);
                                setActiveNavLink(null);
                            }}
                                className={`navtab-content ${activeNavTab === tab.id ? "navtab-display" : ""}`}
                                aria-controlledBy={tab.id}
                                id={tab.name}
                            >
                                {getNavigationTabContent({
                                    tabItems: tab.items,
                                    activeNavGroup: activeNavGroup,
                                    activeNavLink: activeNavLink,
                                    handleActiveNavGroup: handleActiveNavGroup,
                                    handleActiveNavLink: handleActiveNavLink
                                })}
                            </div>
                        </div>
                    )
                )}
            </nav >
            <div className={`corner-widgets ${toggleExpanded ? "responsive" : ""}`}>
                <InstanceBadge instanceId={props.instanceId} headerType={props.headerType} />
                {(
                    props.headerType === "Other" ||
                    props.headerType === "LivePage"
                ) && !!props.instanceId &&
                    <div className="search-bar vertical-center">
                        <SearchInputBar
                            instanceId={props.instanceId!}
                        />
                    </div>
                }
            </div>
        </div>
    );
};